import { User } from 'firebase/auth';
import { Action, Dispatch } from 'redux';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { TravelStoriesList } from '@services/hafh/types/generated';
import { LANG_LOCALE, TravelStoriesTabsType } from '@utils/types';

const api = apiClient(API_TYPES.API);

type TravelStoriesObject = TravelStoriesList & { is_loading: boolean };

const defaultTravelStoriesObject = {
  can_load_more: false,
  current_page: 0,
  is_loading: false,
  travel_stories: [],
} as TravelStoriesObject;

export type TravelStoriesListsState = {
  latest: TravelStoriesObject;
  liked: TravelStoriesObject;
  me: TravelStoriesObject;
  popular: TravelStoriesObject;
};

const initialState: TravelStoriesListsState = {
  latest: defaultTravelStoriesObject,
  liked: defaultTravelStoriesObject,
  me: defaultTravelStoriesObject,
  popular: defaultTravelStoriesObject,
};

// action types
const UPDATE_TRAVEL_STORIES_LIST =
  'hafh/travelStoriesList/UPDATE_TRAVEL_STORIES_LIST' as const;
const RESET_TRAVEL_STORIES_LIST =
  'hafh/travelStoriesList/RESET_TRAVEL_STORIES_LIST' as const;
const UPDATE_IS_LOADING_TRAVEL_STORIES =
  'hafh/travelStoriesList/UPDATE_IS_LOADING_TRAVEL_STORIES' as const;

// reducers
const TravelStoriesLists = (
  state = initialState,
  action:
    | ReturnType<typeof updateTravelStoriesList>
    | ReturnType<typeof resetTravelStoriesListAction>
    | ReturnType<typeof updateIsLoadingTravelStories>
): TravelStoriesListsState => {
  switch (action.type) {
    case UPDATE_TRAVEL_STORIES_LIST: {
      return {
        ...state,
        [action.payload.type]: {
          ...action.payload.travelStoriesList,
          travel_stories: [
            ...state[action.payload.type].travel_stories,
            ...action.payload.travelStoriesList.travel_stories,
          ],
        },
      };
    }

    case RESET_TRAVEL_STORIES_LIST: {
      return {
        ...state,
        [action.payload]: defaultTravelStoriesObject,
      };
    }

    case UPDATE_IS_LOADING_TRAVEL_STORIES: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          is_loading: action.payload.isLoadingTravelStories,
        },
      };
    }

    default: {
      return state;
    }
  }
};

// actions creators
export const updateTravelStoriesList = (
  type: TravelStoriesTabsType,
  travelStoriesList: TravelStoriesList
) => ({
  payload: { travelStoriesList, type },
  type: UPDATE_TRAVEL_STORIES_LIST,
});

export const resetTravelStoriesListAction = (type: TravelStoriesTabsType) => ({
  payload: type,
  type: RESET_TRAVEL_STORIES_LIST,
});

export const updateIsLoadingTravelStories = (
  type: TravelStoriesTabsType,
  isLoadingTravelStories: boolean
) => ({
  payload: { isLoadingTravelStories, type },
  type: UPDATE_IS_LOADING_TRAVEL_STORIES,
});

// actions
export const getTravelStoriesList =
  (
    type: TravelStoriesTabsType,
    page: number,
    locale: LANG_LOCALE,
    authUser?: User
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateIsLoadingTravelStories(type, true));
    const travelStoriesList = await api.get(
      `travel_stories/${type}`,
      {
        page,
      },
      locale,
      authUser
    );

    if (travelStoriesList) {
      dispatch(updateTravelStoriesList(type, travelStoriesList));
    }
    dispatch(updateIsLoadingTravelStories(type, false));
  };

export const resetTravelStoriesList =
  (type: TravelStoriesTabsType) => (dispatch: Dispatch<Action>) => {
    dispatch(resetTravelStoriesListAction(type));
  };

export default TravelStoriesLists;
