/* eslint-disable sort-keys */
const COLORS = {
  primary: '#7149CE',

  red: '#ee7b7a',

  redError: '#F24C4A',
  redError5: '#FFF6F6',

  jalRed: '#E60012',

  black: '#181818',
  primitiveBlack: '#000000',
  lightBlack: '#333333',
  boulder: '#7D7D7D',
  black80: '#464646',
  black75: '#525252',
  black70: '#5c5c5c',
  black50: '#8b8b8b',
  black30: '#b9b9b9',
  black20: '#d0d0d0',
  black10: '#e7e7e7',
  black5: '#f3f3f3',
  black3: '#fafafa',
  black2: '#2f2f2f',

  white: '#fff',
  grey: '#fafafa',
  navy: '#1E1925',

  modalSuccess: '#06BAB5',
  modalError: '#f24c4a',

  greyBorder: '#d6d6d6',
  greyBackground: '#f7f7f7',
  lightBlueBackground: '#E5E5E5',

  // form
  radioBorder: 'rgba(112,112,112, .5)',
  iconArrowLightGray: '#e2e2e2',

  tableBorderColor: 'rgba(0,0,0, .16)',
  tableHeaderColor: '#f1f1f1',

  // buttons
  hoverSecondary: '#E9E2F9',
  signupBorderColor: '#9A5CD9',

  messagePlaceholder: '#d0d0d0',
  messageLinkColor: '#1545d8',

  bookMarkBG: '#F0F0F0',

  black100: '#F6F6F8',
  black200: '#E8E8EC',
  black300: '#D3D1D9',
  black400: '#B9B5C2',
  black500: '#9B93A3',
  black600: '#7D7485',
  black700: '#62586B',
  black800: '#4D4658',
  black900: '#342D3E',

  sky100: '#D0FEFC',
  sky200: '#A2FBFE',
  sky300: '#74EFFC',
  sky400: '#51DDFA',
  sky500: '#19C2F8',
  sky600: '#1298D5',
  sky700: '#EEFBFF',

  red100: '#FFE5EA',
  red200: '#FFC0CC',
  red300: '#F8A0B1',
  red400: '#F9869B',
  red500: '#F96983',
  red600: '#E54461',
  red700: '#C22D48',
  red800: '#9F1B33',
  red900: '#751425',

  pink100: '#F9E1F2',
  pink200: '#F3C3E5',
  pink300: '#EDA0D7',
  pink400: '#EA8CCF',
  pink500: '#E363BF',
  pink600: '#C44CA2',
  pink700: '#A13984',
  pink800: '#7E2966',
  pink900: '#581A46',

  blue100: '#D8F3FF',
  blue200: '#B2E4FF',
  blue300: '#8BD0FF',
  blue400: '#6FBEFF',
  blue500: '#3F9FFF',
  blue600: '#2E7CDB',
  blue700: '#1F5CB7',
  blue800: '#144093',
  blue900: '#0C2D7A',

  brown100: '#FCF9E8',
  brown500: '#CBB67A',
  brown600: '#AE9659',
  brown700: '#92773D',

  lightGray: '#F5F6F8',

  gold200: '#F0E179',
  gold300: '#F5EFE6',
  gold700: '#BF9D63',

  yellow100: '#FCF8DD',
  yellow200: '#F6EEB4',
  yellow300: '#F1E597',
  yellow400: '#F0E179',
  yellow500: '#E7D54E',
  yellow600: '#D5BE29',
  yellow700: '#BBA721',
  yellow800: '#958518',
  yellow900: '#75690E',

  brightYellow: '#FFE500',

  green100: '#E0F5F8',
  green200: '#B2E6EE',
  green300: '#85DAE6',
  green400: '#62CFDF',
  green500: '#1DBACE',
  green600: '#16A5B7',
  green700: '#0F8290',
  green800: '#0B6873',
  green900: '#074951',

  purple100: '#E9E2F9',
  purple200: '#CAB9F2',
  purple300: '#B298EE',
  purple400: '#9A7AE5',
  purple500: '#885FE6',
  purple600: '#7149CE',
  purple700: '#613DB4',
  purple800: '#4B3286',
  purple900: '#35255A',

  regularRank: '#00AD8E',
  silverRank: '#7A7B89',
  goldRank: '#94760C',
  platinumRank: '#7CABE2',
} as const;

export default COLORS;
