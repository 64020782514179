import moment from 'moment';
import router from 'next/router';
import { Location, Neighbor } from '@services/hafh/types/generated';
import { COUNTRY_IDS, LOCALES } from '@utils/constants';

/* eslint-disable */

const Utils = {
  calcAge: (year, month, day) => {
    const birthdate = year * 10000 + month * 100 + day;
    const today = new Date();
    const targetdate =
      today.getFullYear() * 10000 +
      (today.getMonth() + 1) * 100 +
      today.getDate();
    return Math.floor((targetdate - birthdate) / 10000);
  },

  getCurrentYear: () => new Date().getFullYear(),
  getCurrentMonth: () => new Date().getMonth(),

  getYearList: () =>
    [...Array(101).keys()].map((i) => {
      const date = new Date();
      const year = date.getFullYear() - 100;
      const obj = {
        id: i,
        label: `${String(i + year)}`,
        value: i + year,
      };
      return obj;
    }),

  getMonthList: () =>
    [...Array(12).keys()].map((i) => {
      const obj = {
        id: i,
        label: `${String(i + 1)}`,
        value: i + 1,
      };
      return obj;
    }),

  getDayList: () =>
    [...Array(31).keys()].map((i) => {
      const obj = {
        id: i,
        label: `${String(i + 1)}`,
        value: i + 1,
      };
      return obj;
    }),

  getPassportYearList: () =>
    [...Array(11).keys()].map((i) => {
      const date = new Date();
      const year = date.getFullYear();

      const obj = {
        label: `${String(i + year)}`,
        value: i + year,
      };
      return obj;
    }),

  getCreditCardYearList: () =>
    [...Array(10).keys()].map((i) => {
      const date = new Date();
      const year = date.getFullYear();

      const obj = {
        label: `${String(i + year)}`,
        value: i + year,
      };
      return obj;
    }),

  getCreditCardMonthList: () =>
    [...Array(12).keys()].map((i) => {
      const obj = {
        label: `${String(i + 1)}`,
        value: i + 1,
      };
      return obj;
    }),

  toQueryString: (obj) =>
    Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join('&'),

  getParams(query) {
    if (!query) return {};
    return query
      .substr(1)
      .split('&')
      .reduce((acc, cur) => {
        const [k, v] = cur.split('=');
        acc[k] = v;
        return acc;
      }, {});
  },

  timeZoneTokyo(date) {
    return moment(date).utc().add(9, 'hours');
  },

  isBefore(date) {
    return this.timeZoneTokyo().isBefore(this.timeZoneTokyo(date));
  },

  isSameOrBefore(date, units = 'millisecond') {
    return this.timeZoneTokyo().isSameOrBefore(this.timeZoneTokyo(date), units);
  },

  isAfter(date) {
    return this.timeZoneTokyo().isAfter(this.timeZoneTokyo(date));
  },

  isBetween(startDate, endDate) {
    return this.timeZoneTokyo().isBetween(
      this.timeZoneTokyo(startDate),
      this.timeZoneTokyo(endDate),
      'second'
    );
  },
};

export const isSupportedLocale = (locale: string) => LOCALES.has(locale);

export const copyToClipboard = (str) => {
  const textarea = document.createElement('textarea');
  textarea.value = str;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const shuffle = (_array) => {
  const array = _array;
  let currentIndex = array.length;

  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    const temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const formatCalendarDate = (date, langKey) => {
  const formatStr = langKey === 'ja' ? 'MMMDo' : 'MMM Do';
  return moment(date).locale(langKey).format(formatStr);
};

export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const pick = (object, keys) =>
  keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});

export const pickBy = (object, predicate) =>
  Object.keys(object).reduce(
    (acc, key) =>
      predicate(object[key], key) ? { ...acc, ...{ [key]: object[key] } } : acc,
    {}
  );

export const groupBy = (list, iteratee) => {
  const collection = Array.isArray(list) ? list : Object.values(list);
  if (!['string', 'function'].includes(typeof iteratee))
    throw new Error('Iteratee must be a prop string or a function.');
  const validateProp =
    typeof iteratee === 'string' ? (v) => v[iteratee] : (v) => iteratee(v);
  return collection.reduce(
    (r, v, _i, _a, k = validateProp(v)) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  );
};

export const sortBy = (list, iteratees) => {
  /*
    by default, sorting is done in ascending order
    iteratees prefixed with "-" will sort in descending order
   */

  const sorters = Array.isArray(iteratees) ? iteratees : [iteratees];

  const sort = (sorter, v) =>
    typeof sorter === 'function' ? sorter(v) : v[sorter];

  const fieldSorter = (fields) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1;

        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }

        return sort(o, a) > sort(o, b)
          ? dir
          : sort(o, a) < sort(o, b)
          ? -dir
          : 0;
      })
      .reduce((p, n) => p || n, 0);

  return list.sort(fieldSorter(sorters));
};

export const upperCaseWords = (str) =>
  str
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

export const formatName = (str) => {
  let nameStr = str;
  nameStr = nameStr.trim();
  nameStr = upperCaseWords(nameStr);
  return nameStr;
};

export const uuid = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

export const formatInboxDate = (date, now = moment()) => {
  const momentizedDate = moment(date);
  const momentizedNow = moment(now);
  const diffInMinutes = momentizedNow.diff(momentizedDate, 'minutes');

  const humanize = (increment) => {
    const diff = momentizedNow.diff(momentizedDate, increment);
    return moment.duration(-diff, increment).humanize(true);
  };

  if (diffInMinutes < 60) {
    return humanize('minutes');
  } else if (diffInMinutes < 1440) {
    return humanize('hours');
  } else {
    return moment.locale() === 'ja'
      ? momentizedDate.format('M月D日')
      : momentizedDate.format('MMM Do');
  }
};

export const flightReservationListTabPattern = [
  'voucher',
  'upcoming',
  'past',
] as const;

export const isLocationJapan = (locationObj: Location) => {
  if (locationObj && locationObj.country) {
    return locationObj.country.id === COUNTRY_IDS.japan;
  }
  return false;
};

export const isLocationKorea = (locationObj: Location) => {
  if (locationObj && locationObj.country) {
    return locationObj.country.id === COUNTRY_IDS.korea;
  }
  return false;
};

export const isLocationGlobal = (locationObj: Location) => {
  if (locationObj && locationObj.country) {
    return locationObj.country.id === COUNTRY_IDS.global;
  }
  return false;
};

export const isLocationTaiwan = (locationObj: Location) => {
  if (locationObj && locationObj.country) {
    return locationObj.country.id === COUNTRY_IDS.taiwan;
  }
  return false;
};

export const canUseJalFeature = (neighbor: Neighbor) => {
  if (neighbor && !isEmpty(neighbor)) {
    const currentMonthNeighborPlan = neighbor.neighbor_plans[0];
    return !!currentMonthNeighborPlan?.jal;
  }
  return false;
};

export const canUseSplitReservationFeature = (neighbor: Neighbor) => {
  if (neighbor && !isEmpty(neighbor)) {
    const currentMonthNeighborPlan = neighbor.neighbor_plans[0];
    return !!currentMonthNeighborPlan?.split_reservation;
  }
  return false;
};

export const convertWordToPascal = (word: string) => {
  if (word.length < 2) {
    return null;
  }
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const formatDiscountRate = (rate: number) => {
  if (rate > 0 && rate < 1) {
    return `${Math.trunc(rate * 100)}%`;
  }
  return '';
};

export const getMaxOfArray = (array: (number | undefined)[]) => {
  const isErrPattern =
    array.every((num) => num === 0) || array.includes(undefined);

  return isErrPattern ? 0 : Math.max(...(array as number[]));
};

export const getMinOfArray = (array: (number | undefined)[]) => {
  const isErrPattern =
    array.every((num) => num === 0) || array.includes(undefined);

  return isErrPattern ? 0 : Math.min(...(array as number[]));
};

export const getDefaultPrefecture = () => {
  const { locale } = router;
  return locale === 'ja' ? '東京都' : 'Tokyo';
};

export default Utils;
