import { Dispatch } from 'react';
import { AxiosError } from 'axios';
import { User } from 'firebase/auth';
import { Action } from 'redux';
import apiClient, { API_TYPES } from '@services/hafh/api';
import {
  SplitReservation,
  SplitReservationError,
} from '@services/hafh/types/generated';
import toast from '@utils/toast';
import { LANG_LOCALE } from '@utils/types';

const api = apiClient(API_TYPES.API);

export type SplitReservationsState = {
  splitReservation: SplitReservation | null;
};

const initialState: SplitReservationsState = {
  splitReservation: null,
};

export const UPDATE_SPLIT_RESERVATION =
  'hafh/reservations/UPDATE_SPLIT_RESERVATION' as const;

const SplitReservations = (
  state = initialState,
  action: ReturnType<typeof updateSplitReservation>
) => {
  if (action.type === UPDATE_SPLIT_RESERVATION) {
    return {
      ...state,
      splitReservation: action.payload,
    };
  }

  return state;
};

export const updateSplitReservation = (splitReservation: SplitReservation) => ({
  payload: splitReservation,
  type: UPDATE_SPLIT_RESERVATION,
});

export const getSplitReservation =
  (
    splitReservationCode: string,
    authUser: User,
    locale: LANG_LOCALE,
    handleGetSplitReservationError: (error: SplitReservationError) => void
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const splitReservation = await api.get(
      `neighbors/split_reservations/${splitReservationCode}`,
      {},
      locale,
      authUser,
      null,
      (error: AxiosError) =>
        handleGetSplitReservationError(error.response?.data)
    );

    if (splitReservation) {
      return dispatch(updateSplitReservation(splitReservation));
    }
  };

export const acceptSplitReservation =
  (
    splitReservationCode: string,
    authUser: User,
    locale: LANG_LOCALE,
    successCallBack: (confirmationNumber: string) => void,
    errorCallBack?: () => void
  ) =>
  async () => {
    const splitReservation = await api.put(
      `neighbors/split_reservations/${splitReservationCode}/accept`,
      {},
      locale,
      authUser
    );

    if (splitReservation) {
      toast.success('success.acceptedBooking', true);

      if (successCallBack) {
        return successCallBack(splitReservation.confirmation_number);
      }
    } else {
      errorCallBack?.();
    }
  };

export const declineSplitReservation =
  (
    splitReservationCode: string,
    authUser: User,
    locale: LANG_LOCALE,
    successCallBack: (confirmationNumber: string) => void
  ) =>
  async () => {
    const splitReservation = await api.put(
      `neighbors/split_reservations/${splitReservationCode}/decline`,
      {},
      locale,
      authUser
    );

    if (splitReservation) {
      toast.success('success.declinedBooking', true);

      if (successCallBack) {
        return successCallBack(splitReservation.confirmation_number);
      }
    }
  };

export default SplitReservations;
